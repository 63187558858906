    .aboutSection {
        background-color: white;
        /* z-index: 99; */
        position: relative;
        height: 150vh;
        width: 100%;
    }

    .aboutContainer {
        position: absolute;
        top: 20%;
        left: 5%;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
    }

    .aboutContainer .image {
        width: 350px;
        height: 50%;
    }

    .aboutContainer p {
        font-size: 13px;
        line-height: 1.5;
        letter-spacing: 0.05rem;
    }

    .right-side {
        padding-left: 35px;
    }

    .aboutme {
        color: black;
        padding: 90px 0 0px 100px;
        font-size: 1.6rem;
        line-height: 1rem;
        letter-spacing: 0.1rem;
    }

    .aboutme span {
        font-size: 2.5rem;
        color: #FFD401;
        font-weight: 900;
    }

    .image {
        width: 30%;
        /* border-radius: 50%; */
    }

    .aboutContainer button {
        border-radius: 5px;
        color: black;
        box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border: none;
    }

    .aboutContainer button:after {
        content: " ";
        width: 0%;
        height: 100%;
        background: #FFD401;
        position: absolute;
        transition: all 0.4s ease-in-out;
        right: 0;
    }

    .aboutContainer button:hover::after {
        right: auto;
        left: 0;
        width: 100%;
    }

    .aboutContainer button a {
        text-align: center;
        text-decoration: none;
        width: 100%;
        padding: 18px 25px;
        color: black;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.1em;
        /* z-index: 20; */
        transition: all 0.3s ease-in-out;
    }

    .aboutContainer button:hover a {
        color: #183153;
        animation: scaleUp 0.3s ease-in-out;
    }

    .aboutContainer button .icon {
        font-size: 1.0rem;
        margin-left: 5px;
        font-weight: 900;
    }

    @keyframes scaleUp {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(0.95);
        }

        100% {
            transform: scale(1);
        }
    }

    @media (max-width: 800px) {
        .aboutContainer {
            top: 13%;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
        }

        .aboutContainer p {
            padding: 0;
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: 0.05rem;
        }

        img {
            max-width: 100%;
            max-height: 23rem;
            object-fit: cover;
            /* border-radius: 50%; */

        }

        .right-side {
            width: 100%;
            padding: 0;
            margin: 0;
        }

        .aboutme {
            color: black;
            padding: 80px 0 20px 10px;
            font-size: 1.1rem;
            line-height: 1rem;
            letter-spacing: 0.1rem;
        }

        .aboutme span {
            font-size: 2rem;
            color: #FFD401;
            font-weight: 900;
        }

        .aboutContainer button {
            margin-top: 30px;
        }


    }