footer {
    background-color: #F8F8F8;
    border-top: 0.5px solid #E7E7E7;
    text-align: center;
    padding: 10px;
    position: sticky;
    left: 0;
    bottom: 0;
    height: 40px;
}

@media (max-width: 767px) {
    footer {
        text-align: center;
        left: 0;
        bottom: 0;
    }
}

#dark footer {
    background-color: #05386b;
    color: whitesmoke;
}