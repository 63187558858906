/* .main {
    background-color: rgb(225, 223, 223);
}

.switch {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 10px;
}

ReactSwitch {
    font-size: 10px;
}

@media (max-width: 767) {
    .react-switch-bg {
        display: none;
    }
}

.switch label {
    margin: 10px;
}

.main .switch label {
    color: black;
} */
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}