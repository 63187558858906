@mixin breakpoint($point) {
    @if $point ==md {

        // 768px
        @media (min-width: 48em) {
            @content;
        }
    }
}

.header {
    width: 100%;



    @include breakpoint(md) {
        padding: 0 0rem;
    }

    transition: 0.3s ease all;

    &__content {
        height: 70px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 10px;
        margin: 0 0 5px;
        position: fixed;
        z-index: 999;
        top: 0;
        background-color: white;
        border-bottom: solid rgb(192, 190, 190) 0.1px;

        &__logo {
            text-decoration: none;
            color: black;
            font-size: 1.5rem;
            font-weight: 600;
            padding-left: 40px;
            margin-top: 15px;
            cursor: pointer;
        }

        &__nav {
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            height: 100vh;
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: white;
            backdrop-filter: blur(2px);
            transform: translate(0);
            transition: 0.3s ease transform;


            @include breakpoint(md) {
                transform: none;
                flex-direction: row;
                background: transparent;
                width: auto;
                height: 100%;
                position: static;
            }

            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: 32px;

                @include breakpoint(md) {
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 0;
                    margin-right: calc(0.5rem + #{16px});
                }

                li {
                    &:not(:last-child) {
                        margin-bottom: 32px;

                        @include breakpoint(md) {
                            margin-bottom: 0;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: inherit;
                        padding: 0.75rem 1.25rem;
                        border-radius: 12px;
                        font-weight: 600;
                        transition: 0.3s ease all;
                        cursor: pointer;

                        &:hover {
                            background: rgba(#fff, 0.1);
                        }

                        &:active {
                            border-radius: calc(#{12px} + 6px);
                            background: linear-gradient(rgba(#fff, 0.1), rgba(#fff, 0.2));
                        }
                    }

                }

                .nav-item {
                    position: relative;
                    padding: 10px 0px;
                    // margin-right: 5px;
                    cursor: pointer;
                    text-decoration: none;
                    color: black;
                }

                .nav-item:after {
                    background: none repeat scroll 0 0 transparent;
                    bottom: 0;
                    content: "";
                    display: block;
                    height: 2px;
                    left: 50%;
                    position: absolute;
                    background: black;
                    transition: width 0.3s ease 0s, left 0.3s ease 0s;
                    width: 0;
                }

                .nav-item:hover:after {
                    width: 100%;
                    left: 0;
                }
            }

            &.isMenu {
                transform: translate(100%);
            }
        }

        &__toggle {
            margin-right: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 2rem;
            transition: 0.3s ease all;
            position: relative;
            color: black;

            &:hover {
                color: black;
            }

            @include breakpoint(md) {
                display: none;
            }
        }
    }
}