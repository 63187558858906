#mainEl {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;

}

/* cube spinner CSS */

.stage-cube-cont {
    width: 25%;
    height: 25%;
    margin-right: 120px;
}

.cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 15s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    /* margin-left: calc(10% - 100px); */
}

.cubespinner div {
    position: absolute;
    z-index: 9999;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    padding: 50px;
    background: rgba(245, 245, 245, 1);
    text-align: center;
    font-size: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cubespinner .face1 {
    transform: translateZ(150px);
    /* color: #dd0031; */
}

.cubespinner .face2 {
    transform: rotateY(90deg) translateZ(150px);
    /* color: #f06529; */
}

.cubespinner .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(150px);
    /* color: #28a4d9; */
}

.cubespinner .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(150px);
    /* color: #5ed4f4; */
}

.cubespinner .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(150px);
    /* color: #efd81d; */
}

.cubespinner .face6 {
    transform: rotateX(-90deg) translateZ(150px);
    /* color: #ec4d28; */
}

@keyframes spincube {

    from,
    to {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }

    16% {
        transform: rotateY(-90deg);
    }

    33% {
        transform: rotateY(-90deg) rotateZ(90deg);
    }

    50% {
        transform: rotateY(-180deg) rotateZ(90deg);
    }

    66% {
        transform: rotateY(-270deg) rotateX(90deg);
    }

    83% {
        transform: rotateX(90deg);
    }
}


/* cube spinner css ends */

.aboutIntro {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.greeting {
    font-size: 20px;
    font-weight: 500;
    color: white;
    margin-bottom: 50px;
}


.textArea {
    margin: 0;
    line-height: 1rem;
}

h2 {
    color: white;
    font-size: 3rem;
    /* font-weight: 100; */
    line-height: 1.5;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 750px;
}

h2 span {
    color: white;
}

.message {
    background-color: #FFD401;
    color: #333;
    display: block;
    font-weight: 900;
    overflow: hidden;
    position: absolute;
    padding-left: 0.5rem;
    padding-right: 11rem;
    top: 0.2rem;
    left: 260px;
    animation: openclose 7s ease-in-out infinite;
}

@keyframes openclose {
    0% {
        top: 0.2rem;
        width: 0;
    }

    5% {
        width: 0;
    }

    15% {
        width: 230px;
    }

    30% {
        top: 0.2rem;
        width: 230px;
    }

    33% {
        top: 0.2rem;
        width: 0;
    }

    35% {
        top: 0.2rem;
        width: 0;
    }

    38% {
        top: -4.5rem;

    }

    48% {
        top: -4.5rem;
        width: 190px;
    }

    62% {
        top: -4.5rem;
        width: 190px;
    }

    66% {
        top: -4.5rem;
        width: 0;
        text-indent: 0;
    }

    71% {
        top: -9rem;
        width: 0;
        text-indent: 5px;
    }

    86% {
        top: -9rem;
        width: 285px;
    }

    95% {
        top: -9rem;
        width: 285px;
    }

    98% {
        top: -9rem;
        width: 0;
        text-indent: 5px;
    }

    100% {
        top: 0;
        width: 0;
        text-indent: 0;
    }
}

button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: white;
    color: black;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    border: none;
    cursor: pointer;
}

button:after {
    content: " ";
    width: 0%;
    height: 100%;
    background: #FFD401;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
}

button:hover::after {
    right: auto;
    left: 0;
    width: 100%;
}

button span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 18px 25px;
    color: black;
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: 0.2em;
    z-index: 20;
    transition: all 0.3s ease-in-out;
}

button:hover span {
    color: #183153;
    animation: scaleUp 0.3s ease-in-out;
}

button .arrow {
    /* padding: 5px 6px; */
    font-size: 1.2rem;
    margin-left: 7px;
    font-weight: 900;
    /* background-color: black; */
    /* border-radius: 50%; */
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}



/* rotate name around */

.rotate span {
    color: #EDF5E1;
    font-size: 5rem;
    display: inline-flex;
    animation: rotate 3s infinite;
}

@keyframes rotate {

    0%,
    75% {
        transform: rotateY(360deg);
    }
}

.nine span:nth-of-type(1) {
    animation-delay: 0.2s;
}

.nine span:nth-of-type(2) {
    animation-delay: 0.4s;
}

.nine span:nth-of-type(3) {
    animation-delay: 0.6s;
}

.nine span:nth-of-type(4) {
    animation-delay: 0.8s;
}

.nine span:nth-of-type(5) {
    animation-delay: 1s;
}

.nine span:nth-of-type(6) {
    animation-delay: 1.2s;
}

.myname {
    text-transform: uppercase;
    font-family: verdana;
    font-weight: 700;
}

.socialsResume {
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

}

.socialsResume li {
    list-style-type: none;
    display: inline;
    margin-right: 40px;
}

.socialsResume .lni:hover {
    transition: all ease-in-out;
    transition-duration: 0.9s;
    transform: scale(1.2);
}

.lni {
    font-size: 40px;
}

.lni-linkedin-original {
    color: white;
    background-color: black;
}

.lni-github-original {
    color: white;
    background-color: black;
    border-radius: 100px;
}

.lni-instagram-original {
    color: white;
    background-color: black;
}

/* media */

@media (max-width: 767px) {
    #mainEl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    button span {
        text-align: center;
        text-decoration: none;
        width: 100%;
        padding: 10px 15px;
        color: black;
        font-size: 1.1em;
    }

    .aboutIntro {
        width: 90%;
        margin: 0;
    }

    .greeting {
        font-size: 20px;
        font-weight: 500;
        color: white;
        margin-bottom: 50px;
    }


    .rotate span {
        font-size: 3rem;
    }

    h2 {
        display: none;
    }

    .socialsResume {
        margin-bottom: 50px;
    }

    .stage-cube-cont {
        /* margin: 20px; */
    }

    .cubespinner div {
        padding: 0;
        font-size: 100px;
    }

    .cubespinner .face1 {
        transform: translateZ(100px);
        /* color: #dd0031; */
    }

    .cubespinner .face2 {
        transform: rotateY(90deg) translateZ(100px);
        /* color: #f06529; */
    }

    .cubespinner .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
        /* color: #28a4d9; */
    }

    .cubespinner .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
        /* color: #5ed4f4; */
    }

    .cubespinner .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
        /* color: #efd81d; */
    }

    .cubespinner .face6 {
        transform: rotateX(-90deg) translateZ(100px);
        /* color: #ec4d28; */
    }
}

/* @media (max-width: 990px) {
    #mainEl {
        display: flex;
        flex-direction: column;
    }

    .stage-cube-cont {
        margin-bottom: 20%;
    }
} */