.projectSection {
    background-color: white;
    /* z-index: 99; */
    position: relative;
    height: fit-content;
    padding: 10px 0 0px;
    width: 100%;
    padding-bottom: 50px;
}

.project {
    color: black;
    padding: 90px 0 30px 100px;
    font-size: 1.6rem;
    line-height: 1rem;
    letter-spacing: 0.1rem;
}

.project span {
    font-size: 2.5rem;
    color: #FFD401;
    font-weight: 900;
}

.workBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding: 20px;
    margin-top: 50px;
    border-radius: 10px;
    transition: ease;
    background-color: black;
}

.workBox:first-child {
    margin-top: 0;
}

.imageContainer {
    display: flex;
    align-items: center;
    flex: 0 0 350px;
    border-radius: 10px;
    height: auto;
}

.imageContainer img {
    width: 100%;
    /* height: 70%; */
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
}

.describeContainer {
    flex: 0 1 calc(100% - 350px);
    margin-left: 25px;
    color: white;
}

.describeContainer h3 {
    letter-spacing: 1px;
}

.describeContainer ul {
    list-style: disc inside;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.05rem;
    margin-top: 20px;
}

.describeContainer ul li:not(:first-child) {
    margin-top: 10px;
}

.describeContainer p {
    font-size: 0.9rem;
    margin-top: 20px;
}

.describeContainer h4 {
    font-size: 1rem;
    margin-top: 7px;
    letter-spacing: 1px;

    line-height: 1.2;
}

.describeContainer h4 .techStack {
    font-size: 0.8rem;
    display: inline-block;
    margin: 3px 0;
    background-color: #FFD401;
    border-radius: 3px;
    color: black;
    margin-right: 5px;
    padding: 5px 10px;
}

.describeContainer .techStack {
    color: #edf5e1;
}

.describeContainer .projectLinks {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

button {
    border-radius: 5px;
    color: black;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.6);
    overflow: hidden;
    border: none;
    margin-left: 20px;
    margin-right: 20px;
}

button:after {
    content: " ";
    width: 0%;
    height: 100%;
    background: #FFD401;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
}

button:hover::after {
    right: auto;
    left: 0;
    width: 100%;
}

button a {
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    width: 100%;
    padding: 18px 25px;
    color: black;
    font-weight: 900;
    letter-spacing: 0.2em;
    z-index: 20;
    transition: all 0.2s ease-in-out;
}

button:hover a {
    color: #183153;
    animation: scaleUp 0.3s ease-in-out;
}

button .icon {
    font-size: 1.0rem;
    margin: 0 5px;
    font-weight: 900;
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }

    100% {
        transform: scale(1);
    }
}



@media (max-width: 768px) {
    .workBox {
        display: block;
        padding: 0;
    }

    .describeContainer {
        margin-left: 0;
        padding: 20px;
    }

    .imageContainer {
        flex: none;
    }

    .project {
        color: black;
        padding: 90px 0 0px 10px;
        font-size: 1.1rem;
        line-height: 1rem;
        letter-spacing: 0.1rem;
    }

    .imageContainer img {
        border-radius: 0;
    }

    .project span {
        font-size: 2rem;
        color: #FFD401;
        font-weight: 900;
    }
}

@media (max-width: 990px) {
    .workBox {
        display: flex;
        flex-direction: column;
        border-radius: 0;
    }

    .describeContainer {
        margin-top: 0;
    }

    .imageContainer {
        flex: none;
    }

    .project {
        color: black;
        padding: 60px 0 20px 10px;
        font-size: 1.1rem;
        line-height: 1rem;
        letter-spacing: 0.1rem;
    }

    .project span {
        font-size: 2rem;
        color: #FFD401;
        font-weight: 900;
    }

    .imageContainer img {
        border-radius: 0;
    }

    .describeContainer ul {
        font-size: 12px;

    }
}