.contactSection {
    background-color: black;
    /* z-index: 99; */
    position: relative;
    height: 100vh;
    padding: 10px 0 60px;
    width: 100%;
}

h1 {
    color: white;
    padding: 90px 0 0px 100px;
    font-size: 1.6rem;
    line-height: 1rem;
    letter-spacing: 0.1rem;
}

h1 span {
    font-size: 2.5rem;
    color: #FFD401;
    font-weight: 900;
}

.contactContainer {
    position: relative;
    top: 10%;
    /* left: 10%; */
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f5f4f1;
    padding: 30px;
    border-radius: 5px;
}

.form-item {
    display: flex;
    flex-direction: column;
}

#contactForm {
    margin-top: 10px;
}

.contactFormContainer {
    /* border: solid rgb(165, 162, 162, 0.3) 1px; */
    padding: 50px;
    border-radius: 10px;
}

#contactForm input {
    border-radius: 1px;
    margin-bottom: 10px;
    padding: 10px;
    border: rgb(165, 162, 162, 0.6) 0.5px solid;
}

.contactFormContainer form {
    display: flex;
    flex-direction: column;
}

.form-item h3 {
    color: black;
}



textarea {
    border-radius: 1px;
    border: rgb(165, 162, 162, 0.6)1px solid;
    resize: none;
}

::placeholder {
    font-size: 0.8rem;
    letter-spacing: 1px;
    color: #b4bdc5;
    opacity: 1;
}

.contactFormContainer button {
    width: 100%;
    margin: 0;
    margin-top: 10px;
    border-radius: 0px;
    font-family: "Montserrat", sans-serif;
    background-color: white;
    overflow: hidden;
    border: none;
}

.contactFormContainer button:after {
    content: " ";
    width: 0%;
    height: 100%;
    background: #FFD401;
    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
}

.contactFormContainer button:hover::after {
    right: auto;
    left: 0;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.6);
    width: 100%;
}

.contactFormContainer button span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 18px 25px;
    color: black;
    font-weight: 700;
    letter-spacing: 0.2em;
    z-index: 20;
    transition: all 0.3s ease-in-out;
}

button:hover span {
    color: #183153;
    animation: scaleUp 0.3s ease-in-out;
}

button .icon {
    font-size: 1.2rem;
    margin-left: 6px;
    font-weight: 900;
}

button .icon:after {
    color: black;
}


@media (max-width: 768px) {
    .form-item {
        overflow: hidden;
        margin: 0 0 20px 50px;
    }

    .contactContainer {
        width: 100%;
        color: #333;
        display: block;
        font: inherit;
        flex-direction: column;
        align-items: center;
        padding: 0;
        line-height: 1.3;
        font-size: 1rem;
    }

    .form-item h3 {
        margin: 20px auto;
    }

    #map {
        /* overflow: hidden;
        margin: 0;
        width: 100%; */
        display: none;
    }

    h1 {
        color: white;
        padding: 60px 0 20px 10px;
        font-size: 1.1rem;
        line-height: 1rem;
        letter-spacing: 0.1rem;
    }

    h1 span {
        font-size: 2rem;
        color: #FFD401;
        font-weight: 900;
    }
}

@media (max-width: 990px) {
    .contactContainer {
        display: flex;
        flex-direction: column;
    }

    .form-item {
        margin: 0 60px 50px 35px;
    }

    .form-item h3 {
        margin: 20px auto;
    }

    #map {
        /* overflow: hidden;
        margin: 0;
        width: 100%; */
        display: none;
    }

    h1 {
        color: white;
        padding: 60px 0 20px 10px;
        font-size: 1.1rem;
        line-height: 1rem;
        letter-spacing: 0.1rem;
    }

    h1 span {
        font-size: 2rem;
        color: #FFD401;
        font-weight: 900;
    }
}

#map {
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    height: 100%;
}